import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Chip from "../../components/common/Chip";
import EmptyList from "../../components/common/EmptyList";
import Footer from "../../components/common/Footer";
import "./styles.css";
import { Link } from "react-router-dom";

const Blog = () => {
  const { site, id } = useParams();
  const [blogList, setBlogList] = useState([]);
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    async function fetchBlog() {
      try {
        const listModule = await import(`../../config/${site}/data.js`);
        const { blogList } = listModule;
        setBlogList(blogList);
      } catch (error) {
        console.error("Error loading list:", error);
        setBlogList([]);
      }
    }

    fetchBlog();

    let blog = blogList.find((blog) => blog.id === parseInt(id));
    if (blog) {
      setBlog(blog);
    }
  }, [site, id, blogList]);

  return (
    <>
      <Link className="blog-goBack" to={`/${site}`}>
        <span> &#8592;</span> <span>Go Back</span>
      </Link>
      {blog ? (
        <div className="blog-wrap">
          <header>
            <p className="blog-date">Published {blog.createdAt}</p>
            <h1>{blog.title}</h1>
            <div className="blog-subCategory">
              {blog.subCategory.map((category, i) => (
                <div key={i}>
                  <Chip label={category} />
                </div>
              ))}
            </div>
          </header>
          <img src={blog.cover} alt="cover" />
          <div
            className="blog-body"
            dangerouslySetInnerHTML={{ __html: blog.fullText }}
          />
        </div>
      ) : (
        <EmptyList />
      )}
      <Footer />
    </>
  );
};

export default Blog;
