import React from "react";
import { Link } from "react-router-dom";
import Chip from "../../../common/Chip";
import "./styles.css";

const BlogItem = ({
  blog: {
    description,
    title,
    createdAt,
    authorName,
    authorAvatar,
    cover,
    category,
    id,
    site,
    link,
  },
}) => {
  const linkto = link ? link : `/${site}/${id}`;

  return (
    <div className="blogItem-wrap">
      <Link className="blogItem-link-top" to={linkto}>
        <img className="blogItem-cover" src={cover} alt="cover" />
      </Link>

      <h3>
        <Link className="blogItem-link-top" to={linkto}>
          {title}
        </Link>
      </h3>
      <Link className="blogItem-link-top" to={linkto}>
        <p className="blogItem-desc">{description}</p>
      </Link>
      <footer>
        <div className="blogItem-author">
          <img src={authorAvatar} alt="avatar" />
          <div>
            <h6>{authorName}</h6>
            <p>{createdAt}</p>
          </div>
        </div>
        <Link className="blogItem-link-top" to={linkto}>
          <Chip label={category} />
        </Link>
      </footer>
    </div>
  );
};

export default BlogItem;
