import React from "react";
import "./style.css";

const LinkList = ({ links }) => {
  return (
    <div className="link-list">
      {links.map((link, index) => (
        <a key={index} href={link.url} className="link-item">
          {link.text}
        </a>
      ))}
    </div>
  );
};

export default LinkList;
